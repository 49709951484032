<template>
  <div class="form-field-group">
    <h3 class="form-title">
      Education Details <i class="fas fa-pencil-alt"></i>
    </h3>
    <div
      v-for="(v, index) in v$.educationDetails.$model"
      :key="index"
      class="multisteps-form__content"
      :id="`education-detail-${index}`"
    >
      <button
        v-if="index > 0"
        class="field-close"
        @click.prevent="removeEducationDetails(index)"
      >
        <span class="lnr lnr-cross-circle"></span>
      </button>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="college">School / College<span>*</span></label>
          <div class="form-field">
            <input
              v-model.trim="v.school"
              class="form-control"
              type="text"
              id="college"
            />
            <template
              v-if="
                v$.educationDetails.$each.$response.$data[index].school.$invalid
              "
            >
              <span
                v-if="
                  v.submitted &&
                  !v$.educationDetails.$each.$response.$data[index].school
                    .required
                "
                class="error"
                >* required.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].school
                    .minLength
                "
                class="error"
                >School should have at least 1 characters.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].school
                    .maxLength
                "
                class="error"
                >School should have at most 150 characters.</span
              >
            </template>
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="degree">Degree<span>*</span></label>
          <div class="form-field">
            <input
              v-model.trim="v.degree"
              class="form-control"
              placeholder="Degree"
              type="text"
            />
            <template
              v-if="
                v$.educationDetails.$each.$response.$data[index].degree.$invalid
              "
            >
              <span
                v-if="
                  v.submitted &&
                  !v$.educationDetails.$each.$response.$data[index].degree
                    .required
                "
                class="error"
                >* required.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].degree
                    .minLength
                "
                class="error"
                >Degree should have at least 1 characters.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].degree
                    .maxLength
                "
                class="error"
                >Degree should have at most 150 characters.</span
              >
            </template>
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="specialization">Area of Study<span>*</span></label>
          <div class="form-field">
            <input
              v-model.trim="v.areaOfStudy"
              class="form-control"
              id="specialization"
              placeholder="Area of Study"
              type="text"
            />
            <template
              v-if="
                v$.educationDetails.$each.$response.$data[index].areaOfStudy
                  .$invalid
              "
            >
              <span
                v-if="
                  v.submitted &&
                  !v$.educationDetails.$each.$response.$data[index].areaOfStudy
                    .required
                "
                class="error"
                >* required.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].areaOfStudy
                    .minLength
                "
                class="error"
                >Area Of Study should have at least 1 characters.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].areaOfStudy
                    .maxLength
                "
                class="error"
                >Area Of Study should have at most 150 characters.</span
              >
            </template>
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="duration">From<span>*</span></label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                v-model="v.fromDate"
                :maxDate="new Date()"
                :enableTimePicker="false"
                autoApply
                placeholder="From"
                @update:modelValue="v.toDate = ''"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
              <template
                v-if="
                  v$.educationDetails.$each.$response.$data[index].fromDate
                    .$invalid
                "
              >
                <span
                  v-if="
                    v.submitted &&
                    !v$.educationDetails.$each.$response.$data[index].fromDate
                      .required
                  "
                  class="error"
                  >* required.</span
                >
              </template>
            </div>
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="duration">To<span>*</span></label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                v-model="v.toDate"
                :maxDate="new Date()"
                :disabled="v.present || !v.fromDate"
                :minDate="new Date(v.fromDate)"
                :enableTimePicker="false"
                autoApply
                placeholder="To"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
              <template
                v-if="
                  v$.educationDetails.$each.$response.$data[index].toDate
                    .$invalid
                "
              >
                <span
                  v-if="
                    v.submitted &&
                    !v$.educationDetails.$each.$response.$data[index].toDate
                      .required
                  "
                  class="error"
                  >* required.</span
                >
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="edescription">Description<span>*</span></label>
          <div class="form-field">
            <textarea
              v-model.trim="v.description"
              class="form-control"
              placeholder="Description"
            ></textarea>
            <template
              v-if="
                v$.educationDetails.$each.$response.$data[index].description
                  .$invalid
              "
            >
              <span
                v-if="
                  v.submitted &&
                  !v$.educationDetails.$each.$response.$data[index].description
                    .required
                "
                class="error"
                >* required.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].description
                    .minLength
                "
                class="error"
                >Description should have at least 1 characters.</span
              >
              <span
                v-if="
                  !v$.educationDetails.$each.$response.$data[index].description
                    .maxLength
                "
                class="error"
                >Description should have at most 500 characters.</span
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="add-action--btn">
      <span class="add-more-btn" @click.prevent="addMoreEducationDetail"
        >+ Add Education</span
      >
    </div>
  </div>
</template>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import { helpers, required, minLength, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${(day < 10)? '0'+day : day}/${ (month < 10)? '0'+month : month }/${year}`;
    };

    return {
      format,
      v$: useVuelidate(),
    };
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  computed: {
    educationDetails: {
      get() {
        return this.fields;
      },
      set(value) {
        this.$emit("update:educationDetails", value);
      },
    },
  },
  components: {
    datepicker: Datepicker,
  },
  validations() {
    return {
      educationDetails: {
        $each: helpers.forEach({
          school: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(150),
          },
          areaOfStudy: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(150),
          },
          degree: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(150),
          },
          fromDate: {
            required,
          },
          toDate: {
            required,
          },
          description: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(500),
          },
        }),
      },
    };
  },
  methods: {
    addMoreEducationDetail() {
      this.educationDetails.push({
        Id: "",
        submitted: false,
        school: "",
        areaOfStudy: "",
        degree: "",
        fromDate: "",
        toDate: "",
        description: "",
      });

      setTimeout(() => {
        let id = "education-detail-" + (this.educationDetails.length - 1);
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
    removeEducationDetails(index) {
      this.educationDetails.splice(index, 1);
    },
  },
};
</script>
