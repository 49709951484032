<template>
  <!-- add new profile modal -->
  <div class="custom-modal--content profile-content">
    <h3>CREATE PROFILE (Step {{ currentStep }})</h3>
    <span v-if="false" class="import-form"
      ><span class="lnr lnr-upload"></span> Import</span
    >
    <button class="close" type="button" @click="$emit('close')">×</button>
    <div class="profile-form">
      <div class="multisteps-form__progress d-flex align-items-center">
        <button
          :class="{
            'js-active': currentStep >= 1,
            'js-completed':
              $refs.refPersonalDetailFields &&
              !$refs.refPersonalDetailFields.v$.$invalid,
          }"
          class="multisteps-form__progress-btn"
          type="button"
          @click.prevent="goToStep(1)"
        >
          Personal Details
        </button>
        <button
          :class="{
            'js-active': currentStep >= 2,
            'js-completed':
              $refs.refProfileDetailFields &&
              !$refs.refProfileDetailFields.v$.$invalid,
          }"
          class="multisteps-form__progress-btn"
          type="button"
          @click.prevent="goToStep(2)"
        >
          Profile
        </button>
        <button
          :class="{
            'js-active': currentStep >= 3,
            'js-completed':
              $refs.refWorkDetailFields &&
              !$refs.refWorkDetailFields.v$.$invalid,
          }"
          class="multisteps-form__progress-btn"
          type="button"
          @click.prevent="goToStep(3)"
        >
          Work Details
        </button>
        <button
          :class="{
            'js-active': currentStep >= 4,
            'js-completed':
              $refs.refEducationDetailFields &&
              !$refs.refEducationDetailFields.v$.$invalid,
          }"
          class="multisteps-form__progress-btn"
          type="button"
          @click.prevent="goToStep(4)"
        >
          Education Details
        </button>
      </div>
      <form class="multisteps-form__form" method="post" autocomplete="off">
        <!--Personal form panel-->
        <div
          class="js-active multisteps-form__panel"
          v-show="currentStep === 1"
        >
          <personal-detail-fields
            ref="refPersonalDetailFields"
            :fields="personalDetails"
            @profileCountry="profileDetails.city = ''"
            :countries="dropdown.countries"
            :profileTypes="dropdown.profileType"
          ></personal-detail-fields>
        </div>

        <!--Profile form panel-->
        <div
          class="js-active multisteps-form__panel"
          v-show="currentStep === 2"
        >
          <profile-detail-fields
            ref="refProfileDetailFields"
            :fields="profileDetails"
            :country="personalDetails.country"
            :categories="dropdown.profileCategory"
            :expertLevels="dropdown.profileExpertiseLevel"
            :languages="dropdown.language"
            :languageProficiencies="dropdown.LanguageExpertLevel"
          ></profile-detail-fields>
        </div>

        <!--Work form panel-->
        <div
          class="js-active multisteps-form__panel"
          v-show="currentStep === 3"
        >
          <work-detail-fields
            ref="refWorkDetailFields"
            :fields="workDetails"
            :countries="dropdown.countries"
          ></work-detail-fields>
        </div>

        <!--Education form panel-->
        <div
          class="js-active multisteps-form__panel"
          v-show="currentStep === 4"
        >
          <education-detail-fields
            ref="refEducationDetailFields"
            :fields="educationDetails"
          ></education-detail-fields>
        </div>

        <div class="multisteps-form__controls">
          <div class="button-row d-flex justify-content-between">
            <div>
              <template v-if="currentStep > 1">
                <button
                  class="m-0 js-btn-next line-btn"
                  title="Back"
                  type="button"
                  @click.prevent="prevStep"
                >
                  Back
                </button>
              </template>
            </div>
            <div class="justify-content-end">
              <template v-if="currentStep === 4">
                <button
                  class="js-btn-next primary-btn"
                  title="Submit"
                  type="button"
                  :disabled="loadingStatus"
                  @click.prevent="nextStep"
                >
                  Submit
                </button>
              </template>
              <template v-else>
                <button
                  class="secondary-btn"
                  title="Save"
                  type="button"
                  :disabled="loadingStatus"
                  @click="saveAsDraft"
                  v-if="currentStep > 1"
                >
                  Save
                </button>
                <button
                  class="js-btn-next primary-btn"
                  title="Next"
                  type="button"
                  @click.prevent="nextStep"
                >
                  Next
                </button>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PersonalDetailFields from "./add-profile-roster/PersonalDetailFields.vue";
import ProfileDetailFields from "./add-profile-roster/ProfileDetailFields.vue";
import WorkDetailFields from "./add-profile-roster/WorkDetailFields.vue";
import EducationDetailFields from "./add-profile-roster/EducationDetailFields.vue";
import ProfileRosterService from "../services/profileRoster.service";
import common from "../mixins/common";
import userService from "../services/user.service";

export default {
  name: "AddProfileRoster",
  components: {
    "personal-detail-fields": PersonalDetailFields,
    "profile-detail-fields": ProfileDetailFields,
    "work-detail-fields": WorkDetailFields,
    "education-detail-fields": EducationDetailFields,
  },
  mixins: [common],
  props: ["editProfile"],
  data: () => ({
    dropdown: {
      profileType: [],
      profileCategory: [],
      profileExpertiseLevel: [],
      language: [],
      LanguageExpertLevel: [],
      countries: [],
    },
    currentStep: 1,
    isDraft: 1,
    ProfileId: "",
    loadingStatus: false,
    personalDetails: {
      submitted: false,
      firstName: "",
      lastName: "",
      profileType: "",
      userName: "",
      oldUserName: "",
      country: "",
      password: "",
    },
    profileDetails: {
      submitted: false,
      category: "",
      subCategory: "",
      expertise: "",
      expertLevel: "",
      hourlyPrice: "",
      title: "",
      description: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      address: "",
      avatar: "",
      languages: [
        {
          language: "",
          proficiencyLevel: "",
        },
      ],
      photo: null,
      skills: [],
    },
    workDetails: [
      {
        Id: "",
        submitted: false,
        company: "",
        country: "",
        location: "",
        jobTitle: "",
        fromDate: null,
        toDate: null,
        present: false,
        description: "",
        cities: [],
      },
    ],
    educationDetails: [
      {
        Id: "",
        submitted: false,
        school: "",
        areaOfStudy: "",
        degree: "",
        fromDate: null,
        toDate: null,
        description: "",
      },
    ],
  }),
  async created() {
    await this.getProfile(this.editProfile);
    await this.getDropdownData();
  },
  beforeUnmount() {
    this.$emit("close");
  },
  methods: {
    async goToStep(step) {
      let vm = this;
      if (step > vm.currentStep) {
        if (step <= 2) {
          vm.$refs.refPersonalDetailFields.v$.$touch();
          // vm.personalDetails.submitted = true;
          const isValidatePersonalDetails =
            await vm.$refs.refPersonalDetailFields.v$.$validate();
          if (!isValidatePersonalDetails) return;
          this.currentStep = step;
        }

        if (step <= 3) {
          vm.$refs.refProfileDetailFields.v$.$touch();
          // vm.profileDetails.submitted = true;
          const isValidateProfileDetails =
            await vm.$refs.refProfileDetailFields.v$.$validate();
          if (!isValidateProfileDetails) return;

          this.currentStep = step;
        }

        if (step <= 4) {
          vm.$refs.refWorkDetailFields.v$.$touch();
          /*for (const i in vm.workDetails) {
            vm.workDetails[i].submitted = true;
          }*/

          const isValidateWorkDetails =
            await vm.$refs.refWorkDetailFields.v$.$validate();
          if (!isValidateWorkDetails) return;

          this.currentStep = step;
        }

        return false;
      }

      this.currentStep = step;
    },
    async nextStep() {
      let vm = this;

      /* validate personal detail */
      if (vm.currentStep >= 1) {
        vm.$refs.refPersonalDetailFields.v$.$touch();
        vm.personalDetails.submitted = true;
        const isValidatePersonalDetails =
          await vm.$refs.refPersonalDetailFields.v$.$validate();
        if (!isValidatePersonalDetails) {
          vm.currentStep = 1;
          return;
        }
      }

      if (vm.currentStep >= 2) {
        vm.$refs.refProfileDetailFields.v$.$touch();
        vm.profileDetails.submitted = true;
        const isValidateProfileDetails =
          await vm.$refs.refProfileDetailFields.v$.$validate();
        if (!isValidateProfileDetails) {
          vm.currentStep = 2;
          return;
        }
      }

      if (vm.currentStep >= 3) {
        vm.$refs.refWorkDetailFields.v$.$touch();
        for (const i in vm.workDetails) {
          vm.workDetails[i].submitted = true;
        }

        const isValidateWorkDetails =
          await vm.$refs.refWorkDetailFields.v$.$validate();
        if (!isValidateWorkDetails) {
          vm.currentStep = 3;
          return;
        }
      }

      if (vm.currentStep >= 4) {
        vm.$refs.refEducationDetailFields.v$.$touch();
        for (const i in vm.educationDetails) {
          vm.educationDetails[i].submitted = true;
        }
        const isValidateEducationDetails =
          await vm.$refs.refEducationDetailFields.v$.$validate();
        if (!isValidateEducationDetails) {
          vm.currentStep = 4;
          return;
        }
      }

      if (vm.currentStep === 4) {
        vm.isDraft = 0;
        vm.submitHandler();
      } else {
        vm.currentStep++;
      }
    },
    prevStep() {
      this.currentStep--;
    },
    async submitHandler() {
      let vm = this;

      vm.loadingStatus = true;

      let formData = new FormData();
      // personal details
      formData.append("currentStep", vm.currentStep);
      formData.append("firstName", vm.personalDetails.firstName);
      formData.append("lastName", vm.personalDetails.lastName);
      if (vm.personalDetails.userName != vm.personalDetails.oldUserName) {
        formData.append("userName", vm.personalDetails.userName);
      }
      formData.append("profileTypeId", vm.personalDetails.profileType);
      formData.append("password", vm.personalDetails.password);
      formData.append("countryId", vm.personalDetails.country);
      formData.append("isDraft", vm.isDraft);
      vm.isDraft = 1;

      formData.append("categoryId", vm.profileDetails.category);
      formData.append("subCategoryId", vm.profileDetails.subCategory);
      formData.append("expertiseId", vm.profileDetails.expertise);
      formData.append("expertiseLevelId", vm.profileDetails.expertLevel);
      formData.append("hourlyRate", vm.profileDetails.hourlyPrice);
      formData.append("title", vm.profileDetails.title);
      formData.append("overview", vm.profileDetails.description);
      formData.append("city", vm.profileDetails.city);
      formData.append("postalCode", vm.profileDetails.postalCode);
      formData.append("address", vm.profileDetails.address);
      formData.append("phoneNumber", vm.profileDetails.phoneNumber);
      formData.append("photo", vm.profileDetails.photo);
      formData.append("avatar", vm.profileDetails.avatar);

      let languages = vm.profileDetails.languages;
      for (let i = 0; i < languages.length; i++) {
        formData.append(
          "languages[" + i + "][languageId]",
          languages[i].language
        );
        formData.append(
          "languages[" + i + "][proficiencyLevelId]",
          languages[i].proficiencyLevel
        );
      }

      // work details
      let workDetails = vm.workDetails;
      for (let i = 0; i < workDetails.length; i++) {
        formData.append("workDetails[" + i + "][Id]", workDetails[i].Id);
        formData.append(
          "workDetails[" + i + "][companyName]",
          workDetails[i].company
        );
        formData.append(
          "workDetails[" + i + "][country]",
          workDetails[i].country
        );
        formData.append(
          "workDetails[" + i + "][city]",
          workDetails[i].location
        );
        formData.append(
          "workDetails[" + i + "][jobTitle]",
          workDetails[i].jobTitle
        );
        formData.append(
          "workDetails[" + i + "][fromDate]",
          workDetails[i].fromDate
            ? vm.$filters.dateFormat(workDetails[i].fromDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "workDetails[" + i + "][toDate]",
          workDetails[i].toDate
            ? vm.$filters.dateFormat(workDetails[i].toDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "workDetails[" + i + "][present]",
          workDetails[i].present ? 1 : 0
        );
        formData.append(
          "workDetails[" + i + "][description]",
          workDetails[i].description
        );
      }

      // education details
      let educationDetails = vm.educationDetails;
      for (let i = 0; i < educationDetails.length; i++) {
        formData.append(
          "educationDetails[" + i + "][Id]",
          educationDetails[i].Id
        );
        formData.append(
          "educationDetails[" + i + "][institute]",
          educationDetails[i].school
        );
        formData.append(
          "educationDetails[" + i + "][areaOfStudy]",
          educationDetails[i].areaOfStudy
        );
        formData.append(
          "educationDetails[" + i + "][degree]",
          educationDetails[i].degree
        );
        formData.append(
          "educationDetails[" + i + "][fromDate]",
          educationDetails[i].fromDate
            ? vm.$filters.dateFormat(educationDetails[i].fromDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "educationDetails[" + i + "][toDate]",
          educationDetails[i].toDate
            ? vm.$filters.dateFormat(educationDetails[i].toDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "educationDetails[" + i + "][description]",
          educationDetails[i].description
        );
      }

      if (vm.ProfileId) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await ProfileRosterService.update(
            formData,
            vm.ProfileId
          );
          vm.setResponseData(response);

          if (response.profile_data.IsDraft > 0) {
            this.toastMessage(
              "Profile saved as draft successfully.",
              "success"
            );
          } else {
            vm.toastMessage("Profile created successfully.", "success");
            vm.$emit("close");
          }

          vm.loadingStatus = false;
          vm.$store.commit("loadingStatus", false);
          vm.$emit("updateList");
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.loadingStatus = false;
          vm.$store.commit("loadingStatus", false);
        }
      } else {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await ProfileRosterService.create(formData);
          vm.setResponseData(response);

          if (response.profile_data.IsDraft > 0) {
            this.toastMessage(
              "Profile saved as draft successfully.",
              "success"
            );
          } else {
            vm.toastMessage("Profile created successfully.", "success");
            vm.$emit("close");
          }

          vm.loadingStatus = false;
          vm.$store.commit("loadingStatus", false);
          vm.$emit("updateList");
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.loadingStatus = false;
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    async saveAsDraft() {
      this.isDraft = 1;
      await this.submitHandler();
    },
    async setResponseData(response) {
      let vm = this;
      const ProfileId = response.profile_data.Id;
      vm.ProfileId = ProfileId;

      const FirstName = response.profile_data.profile_personal_detail.FirstName
        ? response.profile_data.profile_personal_detail.FirstName
        : "";
      const LastName = response.profile_data.profile_personal_detail.LastName
        ? response.profile_data.profile_personal_detail.LastName
        : "";
      const ProfileTypeId = response.profile_data.profile_personal_detail
        .ProfileTypeId
        ? response.profile_data.profile_personal_detail.ProfileTypeId
        : "";
      const UserName = response.profile_data.profile_personal_detail.UserName
        ? response.profile_data.profile_personal_detail.UserName
        : "";
      const Country = response.profile_data.profile_personal_detail.Country
        ? response.profile_data.profile_personal_detail.Country
        : "";
      const Password = response.profile_data.profile_personal_detail.Password
        ? response.profile_data.profile_personal_detail.Password
        : "";

      vm.personalDetails = {
        submitted: false,
        firstName: FirstName,
        lastName: LastName,
        profileType: ProfileTypeId,
        userName: UserName,
        oldUserName: UserName,
        country: Country,
        password: Password,
      };

      const CategoryId = response.profile_data.profile_professional_detail
        .CategoryId
        ? response.profile_data.profile_professional_detail.CategoryId
        : "";
      const SubCategoryId = response.profile_data.profile_professional_detail
        .SubCategoryId
        ? response.profile_data.profile_professional_detail.SubCategoryId
        : "";
      const CategoryExpertiseId = response.profile_data
        .profile_professional_detail.CategoryExpertiseId
        ? response.profile_data.profile_professional_detail.CategoryExpertiseId
        : "";
      const SubCategoryExpertiseId = response.profile_data
        .profile_professional_detail.SubCategoryExpertiseId
        ? response.profile_data.profile_professional_detail
            .SubCategoryExpertiseId
        : "";
      const HourlyRate = response.profile_data.profile_professional_detail
        .HourlyRate
        ? response.profile_data.profile_professional_detail.HourlyRate
        : "";
      const Title = response.profile_data.profile_professional_detail.Title
        ? response.profile_data.profile_professional_detail.Title
        : "";
      const Overview = response.profile_data.profile_professional_detail
        .Overview
        ? response.profile_data.profile_professional_detail.Overview
        : "";
      const City = response.profile_data.profile_professional_detail.City
        ? response.profile_data.profile_professional_detail.City
        : "";
      const PostalCode = response.profile_data.profile_professional_detail
        .PostalCode
        ? response.profile_data.profile_professional_detail.PostalCode
        : "";
      const Address = response.profile_data.profile_professional_detail.Address
        ? response.profile_data.profile_professional_detail.Address
        : "";
      const Phone = response.profile_data.profile_professional_detail.Phone
        ? response.profile_data.profile_professional_detail.Phone
        : "";
      const PhotoPath = response.profile_data.profile_professional_detail
        .PhotoPath
        ? process.env.VUE_APP_UPLOAD_BASE_PATH +
          response.profile_data.profile_professional_detail.PhotoPath
        : "";

      const Skills = response.profile_data.profile_skill_mapping
        ? response.profile_data.profile_skill_mapping
        : [];

      vm.profileDetails = {
        submitted: false,
        category: CategoryId,
        subCategory: SubCategoryId,
        expertise: CategoryExpertiseId,
        expertLevel: SubCategoryExpertiseId,
        hourlyPrice: HourlyRate,
        title: Title,
        description: Overview,
        city: City,
        postalCode: PostalCode,
        phoneNumber: Phone,
        address: Address,
        avatar: PhotoPath,
        photo: null,
        skills: Skills,
        languages: [],
      };

      // language details
      const profile_language_detail =
        response.profile_data.profile_language_detail;
      let tempLanguages = [];
      for (let i = 0; i < profile_language_detail.length; i++) {
        tempLanguages.push({
          language: profile_language_detail[i].LanguageId
            ? profile_language_detail[i].LanguageId
            : "",
          proficiencyLevel: profile_language_detail[i].ExpertLevel
            ? profile_language_detail[i].ExpertLevel
            : "",
        });
      }

      if (tempLanguages.length === 0) {
        tempLanguages.push({
          language: "",
          proficiencyLevel: "",
        });
      }
      vm.profileDetails.languages = tempLanguages;

      // work details
      const employment_history = response.profile_data.employment_history;
      let tempWorkDetails = [];
      for (let i = 0; i < employment_history.length; i++) {
        tempWorkDetails.push({
          submitted: false,
          Id: employment_history[i].Id ? employment_history[i].Id : "",
          company: employment_history[i].CompanyText
            ? employment_history[i].CompanyText
            : "",
          country: employment_history[i].Country
            ? employment_history[i].Country
            : "",
          location: employment_history[i].LocationCityId
            ? employment_history[i].LocationCityId
            : "",
          jobTitle: employment_history[i].Title
            ? employment_history[i].Title
            : "",
          fromDate: employment_history[i].WorkingFrom
            ? employment_history[i].WorkingFrom
            : "",
          toDate: employment_history[i].WorkingTo
            ? employment_history[i].WorkingTo
            : "",
          present: employment_history[i].IsCurrent > 0 ? true : false,
          description: employment_history[i].Description
            ? employment_history[i].Description
            : "",
          cities: [],
        });
      }

      if (tempWorkDetails.length === 0) {
        vm.workDetails = [
          {
            Id: "",
            submitted: false,
            company: "",
            country: "",
            location: "",
            jobTitle: "",
            fromDate: null,
            toDate: null,
            present: false,
            description: "",
            cities: [],
          },
        ];
      } else {
        vm.workDetails = tempWorkDetails;
      }

      // education details
      const educationDetails = response.profile_data.education_history;
      let tempEducationDetails = [];
      for (let i = 0; i < educationDetails.length; i++) {
        tempEducationDetails.push({
          submitted: false,
          Id: educationDetails[i].Id ? educationDetails[i].Id : "",
          school: educationDetails[i].InstituteText
            ? educationDetails[i].InstituteText
            : "",
          areaOfStudy: educationDetails[i].Area ? educationDetails[i].Area : "",
          degree: educationDetails[i].Degree ? educationDetails[i].Degree : "",
          fromDate: educationDetails[i].AttendedFrom
            ? educationDetails[i].AttendedFrom
            : "",
          toDate: educationDetails[i].AttendedTo
            ? educationDetails[i].AttendedTo
            : "",
          description: educationDetails[i].Description
            ? educationDetails[i].Description
            : "",
        });
      }

      if (tempEducationDetails.length === 0) {
        vm.educationDetails = [
          {
            Id: "",
            submitted: false,
            school: "",
            areaOfStudy: "",
            degree: "",
            fromDate: null,
            toDate: null,
            description: "",
          },
        ];
      } else {
        vm.educationDetails = tempEducationDetails;
      }

      vm.currentStep = response.profile_data.CurrentStep
        ? response.profile_data.CurrentStep
        : 1;
    },
    async getProfile(profileId) {
      let vm = this;
      if (profileId) {
        vm.loadingStatus = true;
        vm.$store.commit("loadingStatus", true);
        try {
          const response = await ProfileRosterService.getEditProfileDataReform(
            profileId
          );
          await vm.setResponseData(response);

          setTimeout(() => {
            vm.$store.commit("loadingStatus", false);
          }, 400);

          vm.loadingStatus = false;
        } catch (error) {
          let message = "Something went wrong. Please try again.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            message = error.response.data.errors
              .toString()
              .split(",")
              .join("\n");
          }
          vm.toastMessage(message, "error");

          setTimeout(() => {
            vm.$store.commit("loadingStatus", false);
          }, 400);

          vm.loadingStatus = false;
        }
      }
    },
    async getDropdownData() {
      let vm = this;

      try {
        vm.$store.commit("loadingStatus", true);
        const response = await ProfileRosterService.getDropdownData();
        vm.dropdown.profileType = response.profileType;
        vm.dropdown.profileCategory = response.profileCategory;
        vm.dropdown.profileExpertiseLevel = response.profileExpertiseLevel;
        vm.dropdown.language = response.language;
        vm.dropdown.LanguageExpertLevel = response.LanguageExpertLevel;

        const countries = await userService.getCountries();
        vm.dropdown.countries = countries.data;
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$emit("close");
        vm.$store.commit("loadingStatus", false);
      }
    },
  },
};
</script>
