<template>
  <!-- edit profile modal -->
  <!-- side popup modal -->
  <div class="edit-profile custom-scroll">
    <form @submit.prevent="submitHandler()" method="post" autocomplete="off">
      <personal-detail-fields
        ref="refPersonalDetailFields"
        :fields="personalDetails"
        :profileTypes="dropdown.profileType"
        :countries="dropdown.countries"
        @profileCountryChange="(profileDetails.city = ''), getCities()"
      ></personal-detail-fields>
      <profile-detail-fields
        ref="refProfileDetailFields"
        :fields="profileDetails"
        :country="personalDetails.country"
        :categories="dropdown.profileCategory"
        :subCategories="dropdown.subCategories"
        :experties="dropdown.experties"
        :expertLevels="dropdown.profileExpertiseLevel"
        :languages="dropdown.language"
        :languageProficiencies="dropdown.LanguageExpertLevel"
        :cities="dropdown.cities"
        :skills="dropdown.skills"
        @profileChangeCategory="
          (profileDetails.subCategory = ''), (profileDetails.expertise = ''), getSubCategories()
        "
        @profileChangeSubCategory="
          (profileDetails.expertise = ''), getExpertise()
        "
      ></profile-detail-fields>
      <work-detail-fields
        ref="refWorkDetailFields"
        :fields="workDetails"
        :countries="dropdown.countries"
      ></work-detail-fields>
      <education-detail-fields
        ref="refEducationDetailFields"
        :fields="educationDetails"
      ></education-detail-fields>
      <div class="form-controls">
        <div class="button-row d-flex justify-content-end">
          <button
            class="ml-auto secondary-btn"
            title="Cancel"
            type="button"
            @click="$emit('close')"
          >
            CANCEL
          </button>
          <button
            class="ml-3 js-btn-next primary-btn"
            title="Update"
            type="submit"
            :disabled="loadingStatus"
            @change.prevent="submitHandler()"
          >
            UPDATE
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PersonalDetailFields from "../components/edit-profile-roster/PersonalDetailFields.vue";
import ProfileDetailFields from "../components/edit-profile-roster/ProfileDetailFields.vue";
import WorkDetailFields from "../components/edit-profile-roster/WorkDetailFields.vue";
import EducationDetailFields from "../components/edit-profile-roster/EducationDetailFields.vue";
import ProfileRosterService from "../services/profileRoster.service";
import common from "../mixins/common";
import userService from "../services/user.service";

export default {
  name: "AddProfileRoster",
  components: {
    "personal-detail-fields": PersonalDetailFields,
    "profile-detail-fields": ProfileDetailFields,
    "work-detail-fields": WorkDetailFields,
    "education-detail-fields": EducationDetailFields,
  },
  mixins: [common],
  props: ["editProfile"],
  async created() {
    if (this.editProfile.ProfileId) {
      this.ProfileId = this.editProfile.ProfileId;
    }

    if (Object.keys(this.editProfile.personalDetails).length > 0) {
      this.personalDetails = this.editProfile.personalDetails;
    }

    if (Object.keys(this.editProfile.profileDetails).length > 0) {
      this.profileDetails = this.editProfile.profileDetails;
    }

    if (this.editProfile.workDetails.length > 0) {
      this.workDetails = this.editProfile.workDetails;
    }

    if (this.editProfile.educationDetails.length > 0) {
      this.educationDetails = this.editProfile.educationDetails;
    }

    await this.getDropdownData();
  },
  data: () => ({
    dropdown: {
      profileType: [],
      profileCategory: [],
      profileExpertiseLevel: [],
      language: [],
      LanguageExpertLevel: [],
      countries: [],
      subCategories: [],
      experties: [],
      cities: [],
      skills: [],
    },
    currentStep: 1,
    isDraft: 1,
    ProfileId: "",
    loadingStatus: false,
    personalDetails: {
      submitted: false,
      firstName: "",
      lastName: "",
      profileType: "",
      userName: "",
      oldUserName: "",
      country: "",
      password: "",
    },
    profileDetails: {
      submitted: false,
      skills: [],
      category: "",
      subCategory: "",
      expertise: "",
      expertLevel: "",
      hourlyPrice: "",
      title: "",
      description: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      address: "",
      avatar: "",
      languages: [
        {
          language: "",
          proficiencyLevel: "",
        },
      ],
      photo: null,
    },
    workDetails: [
      {
        Id: "",
        submitted: false,
        company: "",
        country: "",
        location: "",
        jobTitle: "",
        fromDate: null,
        toDate: null,
        present: false,
        description: "",
        cities: [],
      },
    ],
    educationDetails: [
      {
        Id: "",
        submitted: false,
        school: "",
        areaOfStudy: "",
        degree: "",
        fromDate: null,
        toDate: null,
        description: "",
      },
    ],
  }),
  methods: {
    async validateForm() {
      let vm = this;
      vm.$refs.refPersonalDetailFields.v$.$touch();
      vm.personalDetails.submitted = true;

      const isValidatePersonalDetails =
        await vm.$refs.refPersonalDetailFields.v$.$validate();

      vm.$refs.refProfileDetailFields.v$.$touch();
      vm.profileDetails.submitted = true;
      const isValidateProfileDetails =
        await vm.$refs.refProfileDetailFields.v$.$validate();

      vm.$refs.refWorkDetailFields.v$.$touch();
      for (const i in vm.workDetails) {
        vm.workDetails[i].submitted = true;
      }

      const isValidateWorkDetails =
        await vm.$refs.refWorkDetailFields.v$.$validate();

      vm.$refs.refEducationDetailFields.v$.$touch();
      for (const i in vm.educationDetails) {
        vm.educationDetails[i].submitted = true;
      }

      const isValidateEducationDetails =
        await vm.$refs.refEducationDetailFields.v$.$validate();

      if (
        !isValidatePersonalDetails ||
        !isValidateProfileDetails ||
        !isValidateWorkDetails ||
        !isValidateEducationDetails
      ) {
        return false;
      } else {
        return true;
      }
    },
    async submitHandler() {
      let vm = this;

      const validate = await vm.validateForm();

      if (!validate) return;

      vm.loadingStatus = true;
      let formData = new FormData();
      formData.append("isDraft", 0);
      // personal details
      formData.append("firstName", vm.personalDetails.firstName);
      formData.append("lastName", vm.personalDetails.lastName);
      if (vm.personalDetails.userName != vm.personalDetails.oldUserName) {
        formData.append("userName", vm.personalDetails.userName);
      }
      formData.append("profileTypeId", vm.personalDetails.profileType);
      formData.append("password", vm.personalDetails.password);
      formData.append("countryId", vm.personalDetails.country);

      formData.append("categoryId", vm.profileDetails.category);
      formData.append("subCategoryId", vm.profileDetails.subCategory);
      formData.append("expertiseId", vm.profileDetails.expertise);
      formData.append("expertiseLevelId", vm.profileDetails.expertLevel);
      formData.append("hourlyRate", vm.profileDetails.hourlyPrice);
      formData.append("title", vm.profileDetails.title);
      formData.append("overview", vm.profileDetails.description);
      formData.append("city", vm.profileDetails.city);
      formData.append("postalCode", vm.profileDetails.postalCode);
      formData.append("address", vm.profileDetails.address);
      formData.append("phoneNumber", vm.profileDetails.phoneNumber);
      formData.append("photo", vm.profileDetails.photo);
      formData.append("avatar", vm.profileDetails.avatar);

      let skills = vm.profileDetails.skills;
      if (skills.length > 0) {
        for (let i = 0; i < skills.length; i++) {
          formData.append("skills[" + i + "]", skills[i]);
        }
      } else {
        formData.append("skills", null);
      }

      let languages = vm.profileDetails.languages;
      for (let i = 0; i < languages.length; i++) {
        formData.append(
          "languages[" + i + "][languageId]",
          languages[i].language
        );
        formData.append(
          "languages[" + i + "][proficiencyLevelId]",
          languages[i].proficiencyLevel
        );
      }

      // work details
      let workDetails = vm.workDetails;
      for (let i = 0; i < workDetails.length; i++) {
        formData.append("workDetails[" + i + "][Id]", workDetails[i].Id);
        formData.append(
          "workDetails[" + i + "][companyName]",
          workDetails[i].company
        );
        formData.append(
          "workDetails[" + i + "][country]",
          workDetails[i].country
        );
        formData.append(
          "workDetails[" + i + "][city]",
          workDetails[i].location
        );
        formData.append(
          "workDetails[" + i + "][jobTitle]",
          workDetails[i].jobTitle
        );
        formData.append(
          "workDetails[" + i + "][fromDate]",
          workDetails[i].fromDate
            ? vm.$filters.dateFormat(workDetails[i].fromDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "workDetails[" + i + "][toDate]",
          workDetails[i].toDate
            ? vm.$filters.dateFormat(workDetails[i].toDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "workDetails[" + i + "][present]",
          workDetails[i].present ? 1 : 0
        );
        formData.append(
          "workDetails[" + i + "][description]",
          workDetails[i].description
        );
      }

      // education details
      let educationDetails = vm.educationDetails;
      for (let i = 0; i < educationDetails.length; i++) {
        formData.append(
          "educationDetails[" + i + "][Id]",
          educationDetails[i].Id
        );
        formData.append(
          "educationDetails[" + i + "][institute]",
          educationDetails[i].school
        );
        formData.append(
          "educationDetails[" + i + "][areaOfStudy]",
          educationDetails[i].areaOfStudy
        );
        formData.append(
          "educationDetails[" + i + "][degree]",
          educationDetails[i].degree
        );
        formData.append(
          "educationDetails[" + i + "][fromDate]",
          educationDetails[i].fromDate
            ? vm.$filters.dateFormat(educationDetails[i].fromDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "educationDetails[" + i + "][toDate]",
          educationDetails[i].toDate
            ? vm.$filters.dateFormat(educationDetails[i].toDate, "YYYY-MM-DD")
            : ""
        );
        formData.append(
          "educationDetails[" + i + "][description]",
          educationDetails[i].description
        );
      }

      try {
        vm.$store.commit("loadingStatus", true);
        await ProfileRosterService.update(formData, vm.ProfileId);

        vm.toastMessage("Profile updated successfully.", "success");
        vm.$emit("close");
        vm.loadingStatus = false;
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.loadingStatus = false;
        vm.$store.commit("loadingStatus", false);
      }
    },
    async getCities() {
      let vm = this;
      vm.cities = [];
      const countryId = vm.personalDetails.country;
      if (countryId) {
        vm.$store.commit("loadingStatus", true);
        await userService
          .getCities(countryId)
          .then((response) => {
            vm.cities = response.data;
          })
          .finally(() => {
            vm.$store.commit("loadingStatus", false);
          });
      }
    },
    async getSubCategories() {
      let vm = this;
      let category = vm.profileDetails.category;
      vm.dropdown.subCategories = [];
      vm.dropdown.experties = [];
      if (category) {
        vm.$store.commit("loadingStatus", true);
        await userService
          .getProfileSubCategories(category)
          .then((response) => {
            vm.dropdown.subCategories = response.data;
          })
          .finally(() => {
            vm.$store.commit("loadingStatus", false);
          });
      }
    },
    async getExpertise() {
      let vm = this;
      let category = vm.profileDetails.subCategory;
      vm.dropdown.experties = [];
      if (category) {
        vm.$store.commit("loadingStatus", true);
        await userService
          .getExpertise(category)
          .then((response) => {
            vm.dropdown.experties = response.data;
          })
          .finally(() => {
            vm.$store.commit("loadingStatus", false);
          });
      }
    },
    async getDropdownData() {
      let vm = this;

      try {
        vm.$store.commit("loadingStatus", true);
        const response = await ProfileRosterService.getDropdownData();
        vm.dropdown.profileType = response.profileType;
        vm.dropdown.profileCategory = response.profileCategory;
        vm.dropdown.profileExpertiseLevel = response.profileExpertiseLevel;
        vm.dropdown.language = response.language;
        vm.dropdown.LanguageExpertLevel = response.LanguageExpertLevel;

        const countries = await userService.getCountries();
        vm.dropdown.countries = countries.data;

        const getProfileSubCategories =
          await userService.getProfileSubCategories(vm.profileDetails.category);

        vm.dropdown.subCategories = getProfileSubCategories
          ? getProfileSubCategories.data
          : [];

        const getExpertise = await userService.getExpertise(
          vm.profileDetails.subCategory
        );
        vm.dropdown.experties = getExpertise ? getExpertise.data : [];

        const getCities = await userService.getCities(
          vm.personalDetails.country
        );
        vm.dropdown.cities = getCities ? getCities.data : [];

        const getSkills = await userService.getSkills();
        vm.dropdown.skills = getSkills ? getSkills.data : [];

        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$emit("close");
        vm.$store.commit("loadingStatus", false);
      }
    },
  },
};
</script>
