<template>
  <div class="multisteps-form__content">
    <div class="row grid-2">
      <div class="col">
        <div class="form-group">
          <input
            v-model.trim="personalDetails.firstName"
            class="form-control"
            placeholder="First Name"
            type="text"
            autofocus
          />
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.firstName.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.personalDetails.firstName.minLength.$invalid"
            class="error"
            >First Name should have at least
            {{ v$.personalDetails.firstName.minLength.$params.min }}.</span
          >
          <span
            v-if="v$.personalDetails.firstName.maxLength.$invalid"
            class="error"
            >First Name should have at most
            {{ v$.personalDetails.firstName.maxLength.$params.max }}.</span
          >
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            v-model.trim="personalDetails.lastName"
            class="form-control"
            placeholder="Last Name"
            type="text"
          />
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.lastName.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.personalDetails.lastName.minLength.$invalid"
            class="error"
            >Last Name should have at least
            {{ v$.personalDetails.lastName.minLength.$params.min }}.</span
          >
          <span
            v-if="v$.personalDetails.lastName.maxLength.$invalid"
            class="error"
            >Last Name should have at most
            {{ v$.personalDetails.lastName.maxLength.$params.max }}.</span
          >
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col">
        <div class="form-group">
          <select
            v-model.trim="personalDetails.profileType"
            class="form-control select-box"
            autocomplete="off"
          >
            <option value="">Profile Type</option>
            <option
              v-for="profileType in profileTypes"
              :key="profileType.id"
              :value="profileType.id"
            >
              {{ profileType.text }}
            </option>
          </select>
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.profileType.required.$invalid
            "
            class="error"
            >* required.</span
          >
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            v-model.trim="personalDetails.userName"
            class="form-control"
            placeholder="User Name"
            type="text"
            autocomplete="off"
          />
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.userName.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.personalDetails.userName.minLength.$invalid"
            class="error"
            >User Name should have at least
            {{ v$.personalDetails.userName.minLength.$params.min }}.</span
          >
          <span
            v-if="v$.personalDetails.userName.isUnique.$invalid"
            class="error"
            >User Name already exist.</span
          >
          <span
            v-if="v$.personalDetails.userName.maxLength.$invalid"
            class="error"
            >User Name should have at most
            {{ v$.personalDetails.userName.maxLength.$params.max }}.</span
          >
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col">
        <div class="form-group">
          <input
            v-model.trim="personalDetails.password"
            class="form-control"
            placeholder="Password"
            type="password"
            autocomplete="off"
          />
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.password.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.password.minLength.$invalid
            "
            class="error"
            >Password should have at least
            {{ v$.personalDetails.password.minLength.$params.min }}
            characters.</span
          >
          <span
            v-if="v$.personalDetails.password.maxLength.$invalid"
            class="error"
            >Password should have at most
            {{ v$.personalDetails.password.maxLength.$params.max }}
            characters.</span
          >
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <Select2
            id="profile-countries"
            v-model.trim="personalDetails.country"
            class="select2-box"
            :options="countries"
            :placeholder="`Country`"
            :resetOptions="false"
            :allowClear="true"
            @select="
              (event) => $emit('profileCountry', personalDetails.country)
            "
          />
          <span
            v-if="
              personalDetails.submitted &&
              v$.personalDetails.country.required.$invalid
            "
            class="error"
            >* required.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import UserService from "../../services/user.service";
import Select2 from "../Select2.vue";

const isUniqueUserName = helpers.withAsync(async (value, fields) => {
  if (value === "" || fields.oldUserName === value) return true;
  return UserService.isUniqueUserName(value);
});

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Select2 },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
      default: Array,
    },
    profileTypes: {
      type: Array,
      required: true,
      default: Array,
    },
  },
  computed: {
    personalDetails: {
      get() {
        this.asyncDataFn();
        return this.fields;
      },
      set(value) {
        this.$emit("update:personalDetails", value);
      },
    },
  },
  validations() {
    return {
      personalDetails: {
        firstName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20),
        },
        lastName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20),
        },
        profileType: { required },
        userName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(200),
          isUnique: helpers.withAsync(isUniqueUserName),
          $autoDirty: true,
        },
        country: { required },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(50),
        },
      },
    };
  },
  methods: {
    async asyncDataFn() {
      //await this.getProfileTypes();
      //await this.getCountries();
    },
    async getProfileTypes() {
      let vm = this;
      await UserService.getProfileTypes().then((response) => {
        vm.profileTypes = response.data;
      });
    },
    async getCountries() {
      let vm = this;
      await UserService.getCountries().then((response) => {
        vm.countries = response.data;
      });
    },
  },
};
</script>
