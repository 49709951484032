<template>
  <div class="multisteps-form__content">
    <div class="company-form custom-scroll">
      <div
        v-for="(v, index) in v$.workDetails.$model"
        :key="index"
        class="multi-companies close-wrap"
        :id="`work-detail-${index}`"
      >
        <button
          v-if="index > 0"
          class="field-close"
          @click.prevent="workDetails.splice(index, 1)"
        >
          <span class="lnr lnr-cross-circle"></span>
        </button>
        <div class="step-form--label">
          <label>ADD EMPLOYMENT</label>
        </div>
        <div class="row grid-2">
          <div class="col">
            <div class="form-group">
              <input
                v-model.trim="v.company"
                class="form-control"
                placeholder="Company"
                type="text"
              />
              <template
                v-if="
                  v$.workDetails.$each.$response.$data[index].company.$invalid
                "
              >
                <span
                  v-if="
                    v.submitted &&
                    !v$.workDetails.$each.$response.$data[index].company
                      .required
                  "
                  class="error"
                  >* required.</span
                >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].company
                      .minLength
                  "
                  class="error"
                  >Company should have at least 1 characters.</span
                >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].company
                      .maxLength
                  "
                  class="error"
                  >Company should have at most 150 characters.</span
                >
              </template>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <input
                v-model.trim="v.jobTitle"
                class="form-control"
                placeholder="Title"
                type="text"
              />
              <template
                v-if="
                  v$.workDetails.$each.$response.$data[index].jobTitle.$invalid
                "
              >
                <span
                  v-if="
                    v.submitted &&
                    !v$.workDetails.$each.$response.$data[index].jobTitle
                      .required
                  "
                  class="error"
                  >* required.</span
                >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].jobTitle
                      .minLength
                  "
                  class="error"
                  >Title should have at least 1 characters.</span
                >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].jobTitle
                      .maxLength
                  "
                  class="error"
                  >Title should have at most 150 characters.</span
                >
              </template>
            </div>
          </div>
        </div>
        <div class="row grid-2">
          <div class="col">
            <div class="form-group">
              <Select2
                :id="`work-country-${index}`"
                v-model.trim="v.country"
                class="select2-box"
                :options="countries"
                :placeholder="`Country`"
                :resetOptions="false"
                :allowClear="true"
                @select="(v.location = ''), getCities(v.country, index)"
              />
              <template
                v-if="
                  v$.workDetails.$each.$response.$data[index].country.$invalid
                "
              >
                <span
                  v-if="
                    v.submitted &&
                    !v$.workDetails.$each.$response.$data[index].country
                      .required
                  "
                  class="error"
                  >* required.</span
                >
              </template>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <Select2
                :id="`work-city-${index}`"
                v-model.trim="v.location"
                class="select2-box"
                :options="v.cities"
                :placeholder="`Location`"
                :resetOptions="false"
                :allowClear="true"
              />
              <template
                v-if="
                  v$.workDetails.$each.$response.$data[index].location.$invalid
                "
              >
                <span
                  v-if="
                    v.submitted &&
                    !v$.workDetails.$each.$response.$data[index].location
                      .required
                  "
                  class="error"
                  >* required.</span
                >
              </template>
            </div>
          </div>
        </div>

        <div class="row grid-2 align-items-end">
          <div class="col">
            <div class="form-group">
              <label class="step-field--label">Working Period</label>
              <div class="date-field">
                <datepicker
                  v-model="v.fromDate"
                  :maxDate="new Date()"
                  :enableTimePicker="false"
                  autoApply
                  placeholder="From"
                  @update:modelValue="v.toDate = ''"
                  :format="format"
                >
                  <template #input-icon>
                    <span class="lnr lnr-calendar-full"></span>
                  </template>
                </datepicker>
                <template
                  v-if="
                    v.submitted &&
                    v$.workDetails.$each.$response.$data[index].fromDate
                      .$invalid
                  "
                >
                  <span
                    v-if="
                      !v$.workDetails.$each.$response.$data[index].fromDate
                        .required
                    "
                    class="error"
                    >* required.</span
                  >
                </template>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="date-field">
                <datepicker
                  v-model="v.toDate"
                  :disabled="v.present || !v.fromDate"
                  :minDate="new Date(v.fromDate)"
                  :maxDate="new Date()"
                  :enableTimePicker="false"
                  autoApply
                  placeholder="To"
                  :format="format"
                >
                  <template #input-icon>
                    <span class="lnr lnr-calendar-full"></span>
                  </template>
                </datepicker>
                <template
                  v-if="
                    v.submitted &&
                    v$.workDetails.$each.$response.$data[index].toDate.$invalid
                  "
                >
                  <span
                    v-if="
                      !v$.workDetails.$each.$response.$data[index].toDate
                        .required
                    "
                    class="error"
                    >* required.</span
                  >
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row grid-1">
          <div class="col">
            <div class="form-group">
              <div class="form-checkbox">
                <input
                  :id="`work-${index}`"
                  v-model.trim="v.present"
                  type="checkbox"
                />
                <label :for="`work-${index}`">Currently Working Here</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row grid-1">
          <div class="col">
            <div class="form-group">
              <textarea
                v-model.trim="v.description"
                class="form-control"
                placeholder="Description"
              ></textarea>
              <template
                v-if="
                  v.submitted &&
                  v$.workDetails.$each.$response.$data[index].description
                    .$invalid
                "
              >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].description
                      .required
                  "
                  class="error"
                  >* required.</span
                >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].description
                      .minLength
                  "
                  class="error"
                  >Description should have at least 1 characters.</span
                >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].description
                      .maxLength
                  "
                  class="error"
                  >Description should have at most 500 characters.</span
                >
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <a
        class="add-more-btn"
        href="javascript:void(0);"
        @click.prevent="addMore"
        >+ Add More</a
      >
    </div>
  </div>
</template>
<script>

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  minLength,
  required,
  requiredIf,
  maxLength,
} from "@vuelidate/validators";
import UserService from "../../services/user.service";
import Select2 from "../Select2.vue";

export default {
  setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${(day < 10)? '0'+day : day}/${ (month < 10)? '0'+month : month }/${year}`;
    };

    return {
      format,
      v$: useVuelidate(),
    };
  },
  data: () => ({
    countries: [],
    cities: [],
  }),
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  components: {
    Datepicker,
    Select2,
  },
  computed: {
    workDetails: {
      get() {
        this.asyncDataFn();
        return this.fields;
      },
      set(value) {
        this.$emit("update:workDetails", value);
      },
    },
  },
  validations() {
    return {
      workDetails: {
        $each: helpers.forEach({
          company: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(150),
          },
          country: {
            required,
          },
          location: {
            required,
          },
          jobTitle: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(150),
          },
          fromDate: {
            required,
          },
          toDate: {
            required: requiredIf(function (value, currentObj) {
              if (!currentObj.present) {
                return true;
              } else {
                currentObj.toDate = null;
              }
              return false;
            }),
          },
          description: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(500),
          },
        }),
      },
    };
  },
  methods: {
    async asyncDataFn() {
      await this.getCountries();
      for (let index = 0; index < this.workDetails.length; index++) {
        if (this.workDetails[index].country) {
          await this.getCities(this.workDetails[index].country, index);
        }
      }
    },
    async getCountries() {
      let vm = this;
      await UserService.getCountries().then((response) => {
        vm.countries = response.data;
      });
    },
    async getCities(country, index) {
      const res = await UserService.getCities(country).then((response) => {
        return response.data;
      });
      if (res) {
        this.workDetails[index].cities = res;
      }
    },
    addMore() {
      this.workDetails.push({
        Id: "",
        submitted: false,
        company: "",
        jobTitle: "",
        country: "",
        location: "",
        fromDate: "",
        toDate: "",
        present: false,
        description: "",
      });

      setTimeout(() => {
        let id = "work-detail-" + (this.workDetails.length - 1);
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
  },
};
</script>
