<template>
  <div class="profile-data-wrapper" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_roster &&
        userPermissions.profile_roster.length > 0 &&
        userPermissions.profile_roster.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row">
          <div class="col">
            <a
              class="btn primary-btn"
              href="javascript:void(0);"
              @click.prevent="openSideModal('showOptions')"
              v-if="userPermissions.profile_roster.includes('Add')"
              >Add new Profile</a
            >
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="search-container">
              <input
                class="form-control--filled"
                name="search"
                @keyup="performSearch"
                v-model.trim="searchquery"
                placeholder="Search By Profle, Username, Type.."
                type="text"
              />
              <button type="submit">
                <span class="lnr lnr-magnifier"></span>
              </button>
            </div>
          </div>
          <div class="col">
            <Select2
              id="filter-profile-types"
              v-model="profileType"
              @select="performProfileType"
              :options="profileTypeArray"
              placeholder="All Profile Types"
              class="select2-box"
            />
          </div>
          <div class="col">
            <Select2
              id="filter-profile-skills"
              v-model="skill"
              @select="performSkill"
              :options="skillsArray"
              placeholder="All Skills"
              class="select2-box"
            />
          </div>
        </div>
      </div>
      <div class="profile-table custom-table">
        <table class="table" id="datatable">
          <thead>
            <tr>
              <th>Profile Name</th>
              <th>Username</th>
              <th>Profile Type</th>
              <!-- <th>Skills</th> -->
              <th>Total Contracts</th>
              <th>Hourly Rate ($)</th>
              <th class="no-sort">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="profile in profiles" :key="profile.Id" :id="profile.Id">
              <td>{{ profile.ProfileName }}</td>
              <td>{{ profile.Username }}</td>
              <td>
                <span
                  v-show="false"
                  v-if="
                    profile.profile_personal_detail &&
                    profile.profile_personal_detail.ProfileTypeId
                  "
                  >{{ profile.profile_personal_detail.ProfileTypeId }}</span
                >
                <template
                  v-if="
                    profile.profile_personal_detail &&
                    profile.profile_personal_detail.profile_type &&
                    profile.profile_personal_detail.profile_type.TypeName
                  "
                  >{{
                    profile.profile_personal_detail.profile_type.TypeName
                  }}</template
                >
                <template v-else>N/A</template>
              </td>
              <!-- <td>
                <template
                  v-for="(skill, s_i) in profile.profile_skill_mapping"
                  :key="skill.SkillId"
                >
                  <span v-show="false">{{ skill.SkillId }}</span>
                  <span>
                    {{ s_i > 0 ? ", " : "" }} {{ skill.skill.SkillName }}
                  </span>
                </template>
              </td> -->
              <td>
                <template
                  v-if="
                    userPermissions.contracts &&
                    userPermissions.contracts.length > 0 &&
                    userPermissions.contracts.includes('View')
                  "
                >
                  <router-link
                    class="underline-link"
                    :to="{
                      name: 'ProfileRosterContracts',
                      params: { ID: profile.Id, username: profile.ProfileName },
                    }"
                    >{{ profile.contracts_count }}</router-link
                  >
                </template>
                <template v-else>{{ profile.contracts_count }}</template>
              </td>
              <td>
                <template
                  v-if="
                    profile.profile_professional_detail &&
                    profile.profile_professional_detail.HourlyRate
                  "
                  >{{
                    profile.profile_professional_detail.HourlyRate
                  }}</template
                >
              </td>
              <td>
                <div>
                  <ul class="d-flex">
                    <li
                      v-if="
                        userPermissions.profile_roster.includes('Update') &&
                        profile.IsDraft > 0
                      "
                    >
                      <a
                        class="custom-tooltip"
                        v-tippy="`Edit Profile`"
                        href="javascript:void(0);"
                        @click.prevent="openAddProfileModal(profile.Id)"
                      >
                        <span class="lnr lnr-plus-circle"></span>
                      </a>
                    </li>
                    <li
                      v-if="
                        userPermissions.profile_roster.includes('Update') &&
                        !profile.IsDraft
                      "
                    >
                      <a
                        class="custom-tooltip"
                        v-tippy="`Edit Profile`"
                        href="javascript:void(0);"
                        @click.prevent="
                          openSideModal('editProfile', profile.Id)
                        "
                        ><span class="lnr lnr-pencil"></span
                      ></a>
                    </li>
                    <li>
                      <router-link
                        class="custom-tooltip"
                        v-tippy="`Profile Detail`"
                        :to="{
                          name: 'ProfileRosterDetail',
                          params: { ID: profile.Id },
                        }"
                      >
                        <span class="lnr lnr-eye"></span>
                      </router-link>
                    </li>
                    <li
                      v-if="userPermissions.profile_roster.includes('Delete')"
                    >
                      <a
                        class="custom-tooltip"
                        v-tippy="`Delete Profile`"
                        @click.prevent="deleteProfile(profile.Id)"
                        href="javascript:void(0);"
                      >
                        <span class="lnr lnr-trash"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="profile-footer d-flex justify-content-end">
        <button
          class="primary-btn"
          v-if="profiles && isLoaded"
          type="button"
          @click="downloadCsv()"
        >
          <i class="fas fa-download"></i>Download
        </button>
      </div>
      <div class="custom-modal">
        <div class="custom-modal--inner modal-lg">
          <add-profile-roster
            v-if="addProfileModal.status"
            @close="closeAddProfileModal"
            :editProfile="addProfileModal.profileId"
            @updateList="getProfiles()"
          ></add-profile-roster>
        </div>
      </div>

      <SideModal
        @close="closeSideModal"
        v-bind="{
          contentClass: {
            'edit-profile-modal': editProfileModal.status,
            'sm-side--modal': contract.show || contract.import.show || profile.show || profile.import.show,
            'contract-form': contract.manual.show,
          },
        }"
      >
        <template v-slot:header>
          <template v-if="editProfileModal.status">EDIT PROFILE</template>
          <template v-if="contract.show">CREATE A NEW CONTRACT</template>
          <template v-if="profile.show">ADD NEW PROFILE</template>
          <template v-if="contract.manual.show">CREATE CONTRACT</template>
          <template v-if="contract.import.show"
            >IMPORT CONTRACT {{ contract.profile.Name }}</template
          >
          <template v-if="profile.import.show"
            >IMPORT PROFILE</template>
        </template>
        <template v-slot:body>
          <edit-profile-roster
            v-if="editProfileModal.status"
            :editProfile="editProfileModal.data"
            @close="closeSideModal"
            @updateList="getProfiles()"
          ></edit-profile-roster>
          <div class="new-record--wrap text-center" v-if="contract.show">
            <a
              href="javascript:void(0);"
              class="secondary-btn w-100"
              v-on:click.prevent="
                openSideModal('createContract', contract.profile.Id, 'manual')
              "
              >create a new contract manually</a
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              v-on:click.prevent="
                openSideModal('createContract', contract.profile.Id, 'import')
              "
              >import data</a
            >
          </div>
          <div class="new-record--wrap text-center" v-if="profile.show">
            <a
              href="javascript:void(0);"
              class="secondary-btn w-100"
              v-on:click.prevent="
                openAddProfileModal()
              "
              >create a new profile manually</a
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              v-on:click.prevent="
                openSideModal('importProfile', 0, 'import')
              "
              >import data</a
            >
          </div>
          <add-edit-contract
            v-if="contract.manual.show"
            :profile="contract.profile"
            @refreshContracts="getProfiles()"
            @close="closeSideModal"
          ></add-edit-contract>
          <import-contract
            v-if="contract.import.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @contractImported="getProfiles()"
          ></import-contract>
          <import-profile
            v-if="profile.import.show"
            @close="closeSideModal"
            @profileImported="getProfiles()"
          ></import-profile>
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_roster &&
        (userPermissions.profile_roster.length === 0 ||
          (userPermissions.profile_roster.length > 0 &&
            !userPermissions.profile_roster.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import AddProfileRoster from "../components/AddProfileRosterModal.vue";
import EditProfileRoster from "../components/EditProfileRosterModal.vue";
import AddEditContract from "../components/AddEditContract.vue";
import ImportContract from "../components/ImportContract.vue";
import ImportProfile from "../components/ImportProfile.vue";
import profileRosterService from "../services/profileRoster.service.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import SideModal from "../components/SideModal.vue";
import common from "../mixins/common";
import Select2 from "../components/Select2.vue";

export default {
  name: "ProfileRoster",
  data: () => ({
    loading: true,
    addProfileModal: {
      status: false,
      profileId: "",
    },
    profile: {
      show: false,
      import: {
        show: false,
      },
    },
    contract: {
      show: false,
      profile: {
        Id: "",
        Name: "",
      },
      manual: {
        show: false,
      },
      import: {
        show: false,
      },
    },
    searchquery: null,
    profileType: "",
    skill: "",
    profileTypeArray: [],
    skillsArray: [],
    editProfileModal: {
      status: false,
      data: {
        currentStep: 1,
        ProfileId: "",
        personalDetails: {},
        profileDetails: {},
        workDetails: [],
        educationDetails: [],
      },
    },
    profiles: [],
    isLoaded: false,
  }),
  components: {
    AddProfileRoster,
    EditProfileRoster,
    AddEditContract,
    ImportContract,
    SideModal,
    Select2,
    ImportProfile,
  },
  mixins: [common],
  methods: {
    async openSideModal(action, profileId, method = "") {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      vm.contract.show = false;
      if (
        vm.userPermissions &&
        vm.userPermissions.profile_roster &&
        vm.userPermissions.profile_roster.length > 0 &&
        vm.userPermissions.profile_roster.includes("View")
      ) {
        if (action === "editProfile" && vm.userPermissions.profile_roster.includes("Update")) {
          vm.$store.commit("loadingStatus", true);
          await profileRosterService
            .getEditProfileDataReform(profileId)
            .then(async (response) => {
              const ProfileId = response.profile_data.Id;
              vm.editProfileModal.data.ProfileId = ProfileId;

              const FirstName = response.profile_data.profile_personal_detail
                .FirstName
                ? response.profile_data.profile_personal_detail.FirstName
                : "";
              const LastName = response.profile_data.profile_personal_detail
                .LastName
                ? response.profile_data.profile_personal_detail.LastName
                : "";
              const ProfileTypeId = response.profile_data
                .profile_personal_detail.ProfileTypeId
                ? response.profile_data.profile_personal_detail.ProfileTypeId
                : "";
              const UserName = response.profile_data.profile_personal_detail
                .UserName
                ? response.profile_data.profile_personal_detail.UserName
                : "";
              const Country = response.profile_data.profile_personal_detail
                .Country
                ? response.profile_data.profile_personal_detail.Country
                : "";
              const Password = response.profile_data.profile_personal_detail
                .Password
                ? response.profile_data.profile_personal_detail.Password
                : "";

              vm.editProfileModal.data.personalDetails = {
                submitted: false,
                firstName: FirstName,
                lastName: LastName,
                profileType: ProfileTypeId,
                userName: UserName,
                oldUserName: UserName,
                country: Country,
                password: Password,
              };

              const CategoryId = response.profile_data
                .profile_professional_detail.CategoryId
                ? response.profile_data.profile_professional_detail.CategoryId
                : "";
              const SubCategoryId = response.profile_data
                .profile_professional_detail.SubCategoryId
                ? response.profile_data.profile_professional_detail
                    .SubCategoryId
                : "";
              const CategoryExpertiseId = response.profile_data
                .profile_professional_detail.CategoryExpertiseId
                ? response.profile_data.profile_professional_detail
                    .CategoryExpertiseId
                : "";
              const SubCategoryExpertiseId = response.profile_data
                .profile_professional_detail.SubCategoryExpertiseId
                ? response.profile_data.profile_professional_detail
                    .SubCategoryExpertiseId
                : "";
              const HourlyRate = response.profile_data
                .profile_professional_detail.HourlyRate
                ? response.profile_data.profile_professional_detail.HourlyRate
                : "";
              const Title = response.profile_data.profile_professional_detail
                .Title
                ? response.profile_data.profile_professional_detail.Title
                : "";
              const Overview = response.profile_data.profile_professional_detail
                .Overview
                ? response.profile_data.profile_professional_detail.Overview
                : "";
              const City = response.profile_data.profile_professional_detail
                .City
                ? response.profile_data.profile_professional_detail.City
                : "";
              const PostalCode = response.profile_data
                .profile_professional_detail.PostalCode
                ? response.profile_data.profile_professional_detail.PostalCode
                : "";
              const Address = response.profile_data.profile_professional_detail
                .Address
                ? response.profile_data.profile_professional_detail.Address
                : "";
              const Phone = response.profile_data.profile_professional_detail
                .Phone
                ? response.profile_data.profile_professional_detail.Phone
                : "";
              const PhotoPath = response.profile_data
                .profile_professional_detail.PhotoPath
                ? process.env.VUE_APP_UPLOAD_BASE_PATH +
                  response.profile_data.profile_professional_detail.PhotoPath
                : "";

              const profile_skill_mapping =
                response.profile_data.profile_skill_mapping;
              let Skills = [];
              for (
                let index = 0;
                index < profile_skill_mapping.length;
                index++
              ) {
                Skills.push(profile_skill_mapping[index].SkillId);
              }

              vm.editProfileModal.data.profileDetails = {
                submitted: false,
                category: CategoryId,
                subCategory: SubCategoryId,
                expertise: CategoryExpertiseId,
                expertLevel: SubCategoryExpertiseId,
                hourlyPrice: HourlyRate,
                title: Title,
                description: Overview,
                city: City,
                postalCode: PostalCode,
                phoneNumber: Phone,
                address: Address,
                avatar: PhotoPath,
                photo: null,
                skills: Skills,
                languages: [],
              };

              // language details
              const profile_language_detail =
                response.profile_data.profile_language_detail;
              let tempLanguages = [];
              for (let i = 0; i < profile_language_detail.length; i++) {
                tempLanguages.push({
                  language: profile_language_detail[i].LanguageId
                    ? profile_language_detail[i].LanguageId
                    : "",
                  proficiencyLevel: profile_language_detail[i].ExpertLevel
                    ? profile_language_detail[i].ExpertLevel
                    : "",
                });
              }

              if (tempLanguages.length === 0) {
                tempLanguages.push({
                  language: "",
                  proficiencyLevel: "",
                });
              }

              vm.editProfileModal.data.profileDetails.languages = tempLanguages;

              // work details
              const employment_history =
                response.profile_data.employment_history;
              let tempWorkDetails = [];
              for (let i = 0; i < employment_history.length; i++) {
                tempWorkDetails.push({
                  submitted: false,
                  Id: employment_history[i].Id ? employment_history[i].Id : "",
                  company: employment_history[i].CompanyText
                    ? employment_history[i].CompanyText
                    : "",
                  country: employment_history[i].Country
                    ? employment_history[i].Country
                    : "",
                  location: employment_history[i].LocationCityId
                    ? employment_history[i].LocationCityId
                    : "",
                  jobTitle: employment_history[i].Title
                    ? employment_history[i].Title
                    : "",
                  fromDate: employment_history[i].WorkingFrom
                    ? employment_history[i].WorkingFrom
                    : "",
                  toDate: employment_history[i].WorkingTo
                    ? employment_history[i].WorkingTo
                    : "",
                  present: employment_history[i].IsCurrent > 0 ? true : false,
                  description: employment_history[i].Description
                    ? employment_history[i].Description
                    : "",
                  cities: [],
                });
              }

              if (tempWorkDetails.length === 0) {
                vm.editProfileModal.data.workDetails = [
                  {
                    Id: "",
                    submitted: false,
                    company: "",
                    country: "",
                    location: "",
                    jobTitle: "",
                    fromDate: null,
                    toDate: null,
                    present: false,
                    description: "",
                    cities: [],
                  },
                ];
              } else {
                vm.editProfileModal.data.workDetails = tempWorkDetails;
              }

              // education details
              const educationDetails = response.profile_data.education_history;
              let tempEducationDetails = [];
              for (let i = 0; i < educationDetails.length; i++) {
                tempEducationDetails.push({
                  submitted: false,
                  Id: educationDetails[i].Id ? educationDetails[i].Id : "",
                  school: educationDetails[i].InstituteText
                    ? educationDetails[i].InstituteText
                    : "",
                  areaOfStudy: educationDetails[i].Area
                    ? educationDetails[i].Area
                    : "",
                  degree: educationDetails[i].Degree
                    ? educationDetails[i].Degree
                    : "",
                  fromDate: educationDetails[i].AttendedFrom
                    ? educationDetails[i].AttendedFrom
                    : "",
                  toDate: educationDetails[i].AttendedTo
                    ? educationDetails[i].AttendedTo
                    : "",
                  description: educationDetails[i].Description
                    ? educationDetails[i].Description
                    : "",
                });
              }

              if (tempEducationDetails.length === 0) {
                vm.editProfileModal.data.educationDetails = [
                  {
                    Id: "",
                    submitted: false,
                    school: "",
                    areaOfStudy: "",
                    degree: "",
                    fromDate: null,
                    toDate: null,
                    description: "",
                  },
                ];
              } else {
                vm.editProfileModal.data.educationDetails =
                  tempEducationDetails;
              }

              vm.editProfileModal.data.currentStep = response.profile_data
                .CurrentStep
                ? response.profile_data.CurrentStep
                : 1;

              vm.editProfileModal.status = true;
              setTimeout(() => {
                document.body.classList.add("lmodal-open");
              }, 400);
            })
            .catch((error) => {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
            })
            .finally(() => {
              setTimeout(() => {
                vm.$store.commit("loadingStatus", false);
              }, 400);
            });
        } else if (action === "createContract" && vm.userPermissions.profile_roster.includes("Add")) {
          vm.contract.profile.Id = profileId;
          if (method === "manual") {
            document.body.classList.remove("lmodal-open");
            setTimeout(() => {
              this.contract.show = false;
              this.contract.manual.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else if (method === "import") {
            document.body.classList.remove("lmodal-open");
            vm.contract.profile.Id = profileId;

            setTimeout(() => {
              this.contract.show = false;
              this.contract.import.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else {
            vm.contract.show = true;
            document.body.classList.add("lmodal-open");
          }
        }else if(action === "showOptions" && vm.userPermissions.profile_roster.includes("Add")){
          vm.profile.import.show = false;
          vm.profile.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        }else if(action === "importProfile" && vm.userPermissions.profile_roster.includes("Add")){
          this.closeSideModal();
          setTimeout(() => {
            vm.profile.import.show = true;
            document.body.classList.add("lmodal-open");
          }, 300);
        }
      } 
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.editProfileModal.status = false;
        this.profile.show = false;
        this.contract.show = false;
        this.contract.manual.show = false;
        this.contract.import.show = false;
      }, 300);
    },
    openAddProfileModal(profileId = "") {
      this.closeSideModal();
      this.addProfileModal.profileId = profileId;
      if (profileId) {
        this.addProfileModal.status = true;
        setTimeout(() => {
          document.body.classList.add("modal-open");
        }, 300);
      } else {
        document.body.classList.add("modal-open");
        this.addProfileModal.status = true;
      }
    },
    closeAddProfileModal() {
      document.body.classList.remove("modal-open");

      setTimeout(() => {
        this.addProfileModal.status = false;
      }, 300);
    },
    async getProfiles() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.profile_roster &&
        userPermissions.profile_roster.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await profileRosterService.get(null, null, vm.skill);
          vm.profiles = response.list;
          vm.profileTypeArray = response.profileTypes;
          vm.skillsArray = response.skills;

          var dT = $("#datatable").DataTable();
          dT.destroy();

          vm.$nextTick(function () {
            $("#datatable").DataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Profile Roster",
                  extension: ".csv",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
              iDisplayLength: 25,
              bLengthChange: false,
              /* Disable initial sort */
              aaSorting: [],
              columnDefs: [
                { orderable: false, targets: [5] },
                { orderable: true, targets: [0, 1, 2, 3, 4] },
              ],
              info: false,
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .hide();
                } else {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .show();
                }
              },
            });
            $(".dt-buttons, .dataTables_filter").hide();
            vm.isLoaded = true;
            vm.performCustomSearch();
          });

          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    performSearch: function () {
      this.performCustomSearch();
    },
    performProfileType: function () {
      this.performCustomSearch();
    },
    performSkill: function () {
      this.performCustomSearch();
    },
    performCustomSearch: function () {
      var oTable = $("#datatable").dataTable();
      var searchText = " ";
      if (this.searchquery != null) {
        searchText += this.searchquery;
      }

      if (this.profileType != null) {
        searchText += " " + this.profileType;
      }

      if (this.skill != null) {
        searchText += " " + this.skill;
      }

      oTable.fnFilter(searchText);
    },
    deleteProfile: async function (id) {
      let vm = this;

      await vm
        .$swal({
          title: "Delete Profile",
          text: "Are you sure you want to delete this Profile?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#43832a",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async function (result) {
          if (result.isConfirmed) {
            try {
              vm.$store.commit("loadingStatus", true);
              await profileRosterService.destroy(id);
              vm.getProfiles();
              vm.$store.commit("loadingStatus", false);
            } catch (error) {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
              vm.$store.commit("loadingStatus", false);
            }
          }
        });
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
  },
  async mounted() {
    await this.getProfiles();
    this.loading = false;
  },
  watch: {
    userPermissions: {
      handler: function () {
        this.getProfiles();
      },
      deep: true,
    },
  },
};
</script>

